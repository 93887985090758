<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="invoices"
               
                :search="search"
                sort-by="id"
                item-key="id"
                flat
                id="invoice"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2"
                      >Invoice</v-toolbar-title
                    >

                    <v-row>
                      <v-col cols="12" class="pa-10 mx-10 d-flex flex-row justify-space-around"> 
                        <p class="mb-0">Order ID: {{ orderInfo.id }}</p>
                        <p class="mb-0">Payment Status: {{ orderInfo.order_status }}</p>
                        <p class="mb-0">Delivery Status: {{ orderInfo.delivery_status }}</p>
                        <p class="mb-0">User ID: {{ orderInfo.user_id }}</p>
                        <p class="mb-0">Non Verified ID: {{ orderInfo.non_verified_user_id }}</p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <!-- <v-col class="pr-5 pb-5 pt-6">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          filled
                          rounded
                          dense
                          hide-details
                          style="max-height: 5px !important"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="singleExpand"
                          label="Single expand"
                          class="mt-7 pl-10"
                        ></v-switch>
                      </v-col> -->
                    </v-row>

                    <!-- <v-menu
                      :close-on-content-click="false"
                      
                      v-model="dialog"
                      scrollable
                      transition="scale-transition"
                      origin="top right"
                       :nudge-left="220"
                      max-width="420px" 
                    >
                             
                    </v-menu> -->
                  </v-toolbar>
                </template>
                <!-- <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>Order Refrence: </b> {{ orderInfo.id }} <br />
                    <b>Product ID : </b> {{ item.product_id }}<br />
                    <b>Quantity : </b> {{ item.total_quantity }}<br />
                    <b>Price: </b> {{ item.total_price }}<br />
                    <b>Point : </b> {{ item.total_point }}<br />
                    <b>Product Size : </b> {{ item.product_size }}<br />
                    <b>Product Color : </b> {{ item.product_color }}<br />
                    <b>User ID : </b> {{ useraddress[0].user_id }}<br /> 
                    <b> User Name : </b> {{ useraddress[0].name }}<br /> 
                    <b>Phone Number : </b> {{ useraddress[0].phone_number }}<br />
                    <b>Address : </b> {{ useraddress[0].address }}<br />
                    <b>Area : </b> {{ useraddress[0].area }}<br />
                    <b>Location : </b> {{ useraddress[0].location }}<br />
                    <b> User Ip Address : </b> {{ useraddress[0].ip_address }}<br />
                    <b> Non varified User ID : </b> {{ useraddress[0].non_verified_user_id }}<br />
                  </td>
                </template> -->
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    menu: false,
    menu2: false,
    dialog: false,
    search: "",
    headers: [
      { text: "Product Id", value: "product_id", sortable: false  , class: "blue lighten-5"},
      { text: "Product Title", value: "product_name", sortable: false },
      { text: "Quantityssssssssss", value: "total_quantity", sortable: false , class: "blue lighten-5" },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Sub Total", value: "total_price", sortable: false },
      { text: "Point", value: "total_point", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    invoices: [],
    contact: [],
    useraddress: [],
    orderInfo: {},
  }),

  methods: {
    initialize() {
      axios
        .post(`Cart/specific_order/${this.$route.query.id}/`)
        .then((response) => {
          this.orderInfo = response.data.data;
          this.invoices = response.data.data.orders;
          // this.getuseraddress()
        });
    },

    // getuseraddress() {
    //   console.log('....................... contactinfo',this.contact[0].user_id)
    //   axios.post("Cart/show_address/", {
    //     user_id: this.contact[0].user_id,
    //   })
    //   .then((response) => {
    //     this.useraddress = response.data.data;
    //     console.log("this is for getuseraddress array", this.useraddress)
    //   });
    // },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
#invoice{
   /* style="background: #f5f5f5" */
   background: #f5f5f5;
}
.invoice .theme--light.v-table { background-color: #00f; }
</style>


